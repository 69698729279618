import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-75dcd4dd"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "time"
};
const _hoisted_3 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: "News Details",
    "left-arrow": "",
    onClickLeft: $setup.onClickLeft
  }, null, 8, ["onClickLeft"]), _createElementVNode("div", _hoisted_1, _toDisplayString($setup.detailData.title), 1), _createElementVNode("div", _hoisted_2, _toDisplayString($setup.detailData.createDate), 1), _createElementVNode("div", {
    class: "content",
    innerHTML: $setup.detailData.content
  }, null, 8, _hoisted_3)]);
}