import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "newTutorialDetails",

  setup() {
    const route = useRoute();
    const detailData = reactive({
      title: '',
      createDate: '',
      content: ''
    });
    onMounted(() => {
      console.log("Route query data:", route.query.data); // 检查数据传递是否正确

      const html = JSON.parse(route.query.data);
      detailData.content = html.content;
      detailData.title = html.title;
      detailData.createDate = html.createDate;
    }); // 获取传递过来的 id 参数

    const tutorialId = route.query.id;
    console.log("Received tutorial id:", tutorialId);

    const onClickLeft = () => history.back();

    return {
      onClickLeft,
      tutorialId,
      detailData
    };
  }

};